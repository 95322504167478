import React from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Heading,
  Container,
  Center,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import { Link } from "gatsby";

import Layout from "../components/Layout";

const Archives = (props) => {
  const { pageContext } = props;
  const { year, month, volumes } = pageContext;

  const breadcrumbs = [
    <BreadcrumbItem key="breadcrumb-all">
      <BreadcrumbLink href="/archives/">All Volumes</BreadcrumbLink>
    </BreadcrumbItem>,
  ];

  if (year) {
    breadcrumbs.push(
      <BreadcrumbItem key={`breadcrumb-${year}`}>
        <BreadcrumbLink href={`/archives/${year}`}>
          Volume {year}
        </BreadcrumbLink>
      </BreadcrumbItem>
    );
  }

  if (month) {
    breadcrumbs.push(
      <BreadcrumbItem key={`breadcrumb-${month}`}>
        <BreadcrumbLink href={`/archives/${year}/${month}`}>
          Issue {month}
        </BreadcrumbLink>
      </BreadcrumbItem>
    );
  }

  const issues = volumes[year];

  return (
    <Layout>
      <Center>
        <Container paddingTop="150px">
          <Heading>microPublication Archives</Heading>
          {breadcrumbs && (
            <Breadcrumb>{breadcrumbs.map((bc) => bc)}</Breadcrumb>
          )}

          {month && (
            <UnorderedList>
              {Object.entries(issues[month]).map(([index, article]) => {
                const articleId = article
                  .split("/")
                  .pop()
                  .replace(/\./g, "-")
                  .toLowerCase();
                return (
                  <ListItem key={`article-${article}`}>
                    <Link to={`/journals/biology/${articleId}`}>{article}</Link>
                  </ListItem>
                );
              })}
            </UnorderedList>
          )}

          {!month && year && (
            <UnorderedList>
              {Object.entries(volumes[year]).map(([issue, articles]) => {
                return (
                  <ListItem key={`issue-${issue}`}>
                    <Link to={`/archives/${year}/${issue}`}>Issue {issue}</Link>{" "}
                    ({articles.length} microPublications)
                  </ListItem>
                );
              })}
            </UnorderedList>
          )}

          {!year &&
            Object.entries(volumes).map(([volume, issues]) => {
              return (
                <UnorderedList key={`volume-${volume}`}>
                  <ListItem>
                    <Link to={`/archives/${volume}`}>Volume {volume}</Link>
                  </ListItem>
                </UnorderedList>
              );
            })}
        </Container>
      </Center>
    </Layout>
  );
};

export default Archives;
